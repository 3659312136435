import { Howl } from 'howler';
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

/**
 * Manages audio playback at a global level.
 *
 * Starting this simple with just a single sound with a few key features,
 * but could extend this to include multiple sounds and more functionality.
 */
export const AudioContext = createContext<{
  /**
   * @param src omit if just resuming pause
   */
  play: (src?: string, volume?: number) => void;
  pause: () => void;
  stop: () => void;
  isPlaying: boolean;
}>({
  play: () => {},
  pause: () => {},
  stop: () => {},
  isPlaying: false,
});

export const AudioContextProvider: FC = ({ children }) => {
  const player = useRef<Howl>();
  const audioSrc = useRef<string>();
  const [isPlaying, setIsPlaying] = useState(false);

  const unload = useCallback(() => {
    player.current?.unload();
    player.current?.off();
    player.current = undefined;
  }, []);

  useEffect(() => unload, [unload]);

  const play = useCallback(
    (src?: string, volume = 1) => {
      const p = player.current;

      // resume (!player && !src = no-op)
      if (!src || src === audioSrc.current) {
        if (!!p && !p.playing()) {
          p.play();
          setIsPlaying(true);
        }
        return;
      }

      if (!!p) {
        unload();
      }

      const instance = new Howl({
        src,
        volume,
        autoplay: true,
        html5: true, // streaming
      });
      instance.on('end', () => setIsPlaying(false));
      instance.on('playerror', (e) => setIsPlaying(false)); // because we can't autoplay in some cases (e.g. page refresh)
      player.current = instance;
      audioSrc.current = src;
      setIsPlaying(true);
    },
    [unload],
  );

  const pause = useCallback(() => {
    if (player.current) {
      player.current.pause();
      setIsPlaying(false);
    }
  }, []);

  const stop = useCallback(() => {
    if (player.current) {
      player.current.stop();
      setIsPlaying(false);
    }
  }, []);

  return (
    <AudioContext.Provider
      value={{
        play,
        pause,
        stop,
        isPlaying,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
