import { css, Global } from '@emotion/react';
import React from 'react';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';
import './root.scss';

/**
 * Sets up global styles -- for :root variables please use root.scss
 */
const customStyles = css`
  html {
    /* Required css for smoothscroll-anchor-polyfill - see utils/polyfills.ts */
    scroll-behavior: smooth;
    --scroll-behavior: smooth;
  }

  body {
    ${tw`font-primary text-base`}

    h1 {
      ${tw`text-4xl my-2 font-primary`}
    }

    h2 {
      ${tw`text-3xl my-2 font-primary`}
    }

    h3 {
      ${tw`text-xl my-0.5`}
    }

    /* Use this setting for layout-intensive sites when font sizes are overridden by iOS */
    /* -webkit-text-size-adjust: none; */
  }

  /* Focus visible & polyfill */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Tab focus style */
  button:focus,
  input:focus,
  a:focus {
    outline: gray dashed 1px;
  }
`;

export const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);
