export const isDev =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === 'development';

// process.env.CONTEXT is set by Netlify when hosting there
export const isProduction =
  !isDev &&
  (typeof process.env.CONTEXT === 'undefined' ||
    process.env.CONTEXT === 'production');

export const isSSG = typeof window === 'undefined';

export const isNetlify = isSSG
  ? process.env.NETLIFY === 'true'
  : window.location.hostname.includes('netlify.app');

export enum LocalStorageKey {
  visitedLinks = 'dicta.visitedLinks',
  redactEngine = 'dicta.redactEngine',
  selectedCat = 'dicta.selectedCategory',
  audioMuted = 'dicta.audioMuted',
}

export const engineMetadataPattern = /^metadata.*?\n/;
export const engineExtractMetadataPattern = /^(metadata.*?\n)([\s\S]*)/;
export const engineSplitMetadataPattern =
  /^(metadata.*?)(desk:\s*?)(\d+)(\s*?mobile:\s*?)(\d+)(.*?\n)/;

export const AUDIO_VOLUME = 0.7;
