/**
 * "You generally should implement the same components in both
 *  gatsby-ssr.js and gatsby-browser.js so that pages generated
 *  through SSR are the same after being hydrated in the browser."
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { AudioContextProvider } from './src/context/AudioContext';
import { RAFContextProvider } from './src/context/RAFContext';
import { GlobalStyles } from './src/styles/GlobalStyles';
import './src/utils/polyfills';

export const wrapPageElement = ({ element, props }) => (
  <RAFContextProvider>
    <GlobalStyles />
    <AudioContextProvider>{element}</AudioContextProvider>
  </RAFContextProvider>
);
